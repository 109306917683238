import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 媒体商城上架库存
 * @param {Object} data
 * @returns
 */
export function addInventory (data) {
  return request.post('/ooh-product/v1/cloudinventory/addInventory', qs.stringify(data))
}
// 获取媒体商城资源列表
export function getCloudResource (params) {
  return request.get('/ooh-product/v1/cloudinventory/getCloudResource', { params })
}
// 获取库存档期
export function getCloudInventory (data) {
  return request.post('/ooh-product/v1/cloudinventory/getCloudInventory', qs.stringify(data))
}
// 更新库存档期
export function updateInventoryList (data) {
  return request({
    url: '/ooh-product/v1/cloudinventory/updateInventoryList',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}
// 移除库存
export function deleteInventory (data) {
  return request.post('/ooh-product/v1/cloudinventory/deleteInventory', qs.stringify(data))
}
// 获取租户下媒体商城服务项
export function getCloudServiceItem (params) {
  return request.get('/ooh-product/v1/cloudinventory/getCloudServiceItem', { params })
}
// 获取商品详情html
export function getHtml (params) {
  return request.get('/ooh-product/v1/cloudinventory/getHtml', { params })
}
// 更新商品详情html
export function updateHtml (data) {
  return request.post('/ooh-product/v1/cloudinventory/updateHtml', qs.stringify(data))
}

// --------------------------商品附加描述------------------------------------
// 获取商品附加描述
export function getAdditional (params) {
  return request.get('/ooh-product/v1/cloudinventory/getAdditional', { params })
}
// 删除商品附加描述
export function deleteAdditional (params) {
  return request.get('/ooh-product/v1/cloudinventory/deleteAdditional', { params })
}
// 新增或编辑商品附加描述
export function addOrEditAdditional (data) {
  return request.post('/ooh-product/v1/cloudinventory/addOrEditAdditional', qs.stringify(data))
}
// 批量为商品设置附加项
export function addInventoryAdditional (data) {
  return request({
    url: '/ooh-product/v1/cloudinventory/addInventoryAdditional',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}

// 获取商品主图
export function getInventoryPhoto (params) {
  return request.get('/ooh-product/v1/cloudinventory/getInventoryPhoto', { params })
}
// 批量为商品设置主图
export function addInventoryPhoto (data) {
  return request({
    url: '/ooh-product/v1/cloudinventory/addInventoryPhoto',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}
// 获取商品发布状态枚举
export function getInventoryStatus () {
  return request.get('/ooh-product/v1/cloudinventory/getInventoryStatus')
}
// 批量投放商品
export function deliverInventorys (data) {
  return request({
    url: '/ooh-product/v1/cloudinventory/deliverInventorys',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}
// 批量更新库存价格
export function updateSchedulePrice (data) {
  return request({
    url: '/ooh-product/v1/cloudinventory/updateSchedulePrice',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}
// 批量更新库存价格
export function deleteInventorys (data) {
  return request({
    url: '/ooh-product/v1/cloudinventory/deleteInventorys',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}
